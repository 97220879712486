/**
 * --------------------------------------------------------------------------
 * Button visually impaired (v1.0.0): index.umd.js
 * Licensed under MIT (https://github.com/veks/button-visually-impaired-javascript/blob/master/LICENSE)
 * --------------------------------------------------------------------------
 */

/*import 'core-js/stable';*/
import 'regenerator-runtime/runtime'
import Bvi from './bvi'

export default { Bvi }
